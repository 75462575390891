import FileUpload from './FileUpload';
import { useProject } from '@appmaker/core/hooks';
import coreApi from '@appmaker/core/api/core';

const AppFileUpload = (props) => {
	const { projectId } = useProject();
	const {
		name,
		onChange,
		properties: { folderName, tagName, fileNameFrom } = {},
		defaultValue,
	} = props;
	const tag = tagName ?? `${new Date().getTime()}-${name}`;
	const onHandleDropFiles = async (name, files) => {
		if (projectId) {
			const data = new FormData();
			data.append('file', files[0]);
			let extraParams;
			if (folderName) {
				extraParams = `folderName=${folderName}`;
			}

			try {
				const fileUploadResponse = await coreApi.uploadFile(
					projectId,
					data,
					tag,
					extraParams
				);
				if (fileUploadResponse.status && fileUploadResponse.body) {
					const { fileName } = fileUploadResponse.body;
					if (!fileName) {
						onChange({
							previewFile: undefined,
							fileTagName: undefined,
							originalFileName: undefined,
						});
					} else {
						onChange({
							...defaultValue,
							fileName,
						});
					}
				}
			} catch (error) {}
		}
	};
	return (
		<FileUpload
			{...props}
			onFileUpload={onHandleDropFiles}
			onChange={(previewFile) => {
				onChange({
					previewFile,
					fileTagName: tag,
					fileNameFrom,
				});
			}}
			defaultValue={defaultValue?.previewFile}
		/>
	);
};

export default AppFileUpload;
